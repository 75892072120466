import { inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class RouterExtService {
	#previousUrl: string = undefined;
	#currentUrl: string = undefined;
	#router = inject(Router);

	constructor() {
		this.#currentUrl = this.#router.url;
		this.#router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.#previousUrl = this.#currentUrl;
				this.#currentUrl = event.url;
			}
		});
	}

	public getPreviousUrl = () => this.#previousUrl;
}
